import 'bootstrap/dist/js/bootstrap.bundle';
//var jQuery = null;
//import $ from 'jquery';
//jQuery = $

import './jquery-jvectormap-2.0.5.min';

import './jquery-jvectormap-world-en';
import './mapa';

$(function () {
  $(".searchIcon").on("click", function () {
    $(".search").toggleClass("active");
    $(this).toggle()
    $(this).siblings().toggle()
  });
});



(function () {
  'use strict'

  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = document.querySelectorAll('.needs-validation')

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')
      }, false)
    })
})()

const form = document.querySelector("#formContact");
if (form) {
  form.addEventListener("submit", (e) => {
    e.preventDefault();
    
		const formData = $("#formContact").serialize();
    if (form.checkValidity()) {
      $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: $("#formContact").attr("action"),
        data: formData,
        success: function (response) {
            if (typeof response !== 'undefined') {
              const successMessege = document.querySelector("#success");
              if(successMessege){
                successMessege.style.display = "block";
              }
            }
        }
      });
    }
  });
}

