//Obsługa Mapy

$(document).ready(function() {
	
	if($('#world-map').length){
		countries = [];

		countriesList = $.parseJSON (countriesList)
		// console.log(countriesList);
		$.each(countriesList, function(i, item){
			countries.push(i);
		}) 
		$('#world-map').vectorMap({
			map: 'world_mill',
			backgroundColor: 'transparent',
			selectedRegions: countries,
            multiSelectRegion: !0,
            zoomOnScroll: !1,
            regionStyle: {
                initial: { fill: "#d5d3dd", "fill-opacity": 1, stroke: "none", "stroke-width": 0, "stroke-opacity": 1 },
                hover: { "fill-opacity": 1, cursor: "default" },
                selected: { fill: "#E21F0A" },
                selectedHover: { "fill-opacity": 1, fill: "#F32F1B" },
            },
            onRegionTipShow: function (e, o, n) {
				if (-1 === $.inArray(n, countries)) 
					return false;
				else
            		o.html(countriesList[n]);
            },
            onRegionClick: function(e,n){
            	if (-1 === $.inArray(n, countries)) 
					return false;
				else
            		return countriesList[n];
            }
		});
	}
})

